$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$grid-gutter: 32px;

@font-face {
  font-family: 'Bebas Neue';
  src: url("../../fonts/bebas_neue.TTF");
  font-weight: 400;
}

$nunitoFont: 'Nunito Sans', sans-serif;
$openFont: 'Open Sans', sans-serif;
$bebasFont: 'Bebas Neue', sans-serif;
$robotoFont: 'Roboto', sans-serif;
$robotoCondensedFont: 'Roboto Condensed', sans-serif;

$fontColors: (
    blackFont: #000000,
    darkGreyFont: #272727,
    listDarkFont: #2A2A2A,
    greyFont: #3A3A3A,
    listFont: #6b6b6b,
    lightGreyFont: #707070,
    greenFont: #56D093,
    whiteFont: #FFFFFF,
    navyFont: #283583,
    lightBlueFont: #009FE3,
    pinkFont: #e5608e,
    warningFont: #ffc107,
);

$themeColors: (
    themeColorDarkGreen: #08714B,
    themeColorSoftGreen: #95C4B3,
    themeColorLightGreen: #56D093,
    themeColorTransparentGreen: rgba(2, 113, 73, .43),
    themeColorLightBlue: #009FE3,
    themeColorHighlight: #4054CF,
    themeColorBasicText: #656565,
    themeColorDarkText: #4D4D4D,
    themeColorLightGrey: #F3F3F3,
    themeColorMediumGrey: #E6E6E6,
    themeColorTransparentGrey: rgba(243, 243, 243, .80)
);

$pageColors: (
    contactIcon: #60C0DF,
    social: #009FE3,
    socialGrey: #5E5E5E,
    success: #56D093,
    navyColor: #283583,
    textColBackground: #F4F5FF,
    ticketDisabled: #DAE5E9,
    sticker: #810E9A,
    pink: #e5608e,
    white: #FFFFFF,
    switcherGrey: #E5E5E5,
    linearBackground: #F9F9F9,
    whiteOnOpacity: rgba(255, 255, 255, .73),
    opacityOnBackground: rgba(255,255,255,0),
    transparent: transparent,
    neuro: map_get($themeColors, neuro),
    borderGrey: #C2C2C2,
    buttonDisabled: #BCE0CE,
    freeWorkshop: #4FB1B5,
    ticketOption: rgba(86, 208, 147, .25),
    listGrey: #6b6b6b,
    listDarkGrey: #2A2A2A
);

$magwetColors: (
    main: #08714B,
    darkBlueText: #003346,
    subscribe: #67E8A7,
    disabled: #CCE1F3,
    mask: rgba(31, 31, 31, 0.3),
    success: #56D093,
    enhance: #ffcf00,
    footerBackground: #4c4c4c,
    footerWhite: #FFFFFF,
    footerGrey: #898989,
    dropdown: #898989,
    dropdownHover: #00AEEF,
    menuBorder: #5c9494,
);

$insetSpacer: $grid-gutter/2;
$spacer: $grid-gutter/2;