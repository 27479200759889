.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  top: 100%;
  left: -.25rem;
  z-index: 21;
  background-color: white;
  box-shadow: 3px 3px 6px rgba(71, 71, 71, 0.3);

  &_show {
    display: flex;
  }

  &_right {
    left: unset;
    right: 0;
  }

  &__item {
    position: relative;
    padding: .5rem 1rem;
    font-size: 1rem;
    @include setFontColor(greyFont);
    text-decoration: none;
    line-height: 1.5rem;
    @include fontStyling($openFont, 400);
    color: map_get($magwetColors, dropdown);
    margin-left: .5rem;
    white-space: nowrap;

    &:hover {
      color: map_get($magwetColors, dropdownHover);
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: .5rem;
        bottom: .5rem;
        width: 3px;
        background-color: map_get($magwetColors, dropdownHover);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .dropdown {
    position: static;
    box-shadow: none;
    margin: 0 -.5rem ;
  }
}