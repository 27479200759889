.subscribe-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  display: block;
  padding: 1rem;

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    padding: .5rem;
  }

  &__text {
    font-size: 1.625rem;
    color: map_get($magwetColors, main);
    text-transform: uppercase;
    line-height: 2rem;
  }

  &__icon {
    align-self: flex-start;
    margin-right: 1rem;
    padding: .5rem;

    & > svg {
      fill: map_get($magwetColors, main);
      height: 36px;
      width: 36px;
    }
  }

  &__list {
    list-style: none;
    min-width: 28rem;
    font-size: 1rem;
    padding: 0;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2.5rem;
    padding-left: 0.5rem;
    color: black;
    margin: .25rem 0;

    &:hover {
      text-decoration: none;
      background-color: #F1F1F1;
      font-weight: bolder;
      .subscribe-popup__button {
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &__title {
    margin: 0;
  }

  &__button {
    background-color: map_get($magwetColors, success);
    color: white;
    text-transform: uppercase;
    display: none;
    border: none;
    padding: .5rem 1rem;
    height: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .subscribe-popup {
    max-height: 90vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.3rem;
      background-color: white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
      border-radius: 0.1rem;
    }

    &__header {
      &__text {
        font-size: 1.2rem;
      }
    }
    &__list {
      min-width: 80vw;
      overflow-y: scroll;

      &_title {
        font-weight: 300;
      }

      &_link {
        border-bottom: 1px solid #777777;
      }
    }
  }
}
