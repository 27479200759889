.mobile-menu {
  display: flex;
  align-items: center;


  &__link {
    &:after {
      content: '';
      position: absolute;
      border-bottom: 4px solid map_get($magwetColors, success);
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:hover {
      background-color: white;
      color: map_get($magwetColors, main);

      &:after {
        content: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-self: flex-start;
    background-color: map_get($magwetColors, main);
    z-index: 21;
    left: 0;
    top: 43px;
    width: 80%;
    max-width: 20rem;
    transition: .3s transform;
    transform: translateX(-100%);

    &_show {
      transform: translateX(0%);
    }

    &__list {
      flex-direction: column;
      width: 100%;
    }

    &__item {
      border-bottom: 1px solid map_get($magwetColors, menuBorder);
      width: 100%;
      flex-direction: column;

      &_no-border {
        border-bottom: none;
      }
    }

    &__link {
      color: white;
      padding: .25rem 0;

      &:hover {
        @include setThemeColor(background, themeColorDarkGreen);
        color: white;
      }

      &:after {
        content: none;
      }
    }
  }
}