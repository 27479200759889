.link,
.button {
  cursor: pointer;
}

.button {
  @include buttonReset;
  max-width: 100%;
  white-space: normal;

  &_sm {
    padding: 0.25rem 0.75rem
  }

  &_md {
    padding: 0.5rem 1rem;
  }

  &_lg {
    padding: 1rem 1.5rem;
  }

  &_no-padding {
    padding: 0;
  }
}

.button-reset {
  @include buttonReset;
}

.button-search {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  margin-left: -2px;
  border-radius: 2px;
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  padding: 0;
}

.button-success {
  @include buttonSuccess;
}

.button-disabled {
  @include buttonInactive;
  pointer-events: none;
}

.button-success-reverted {
  @include buttonSuccessReverted;

  &_disabled {
    box-shadow: inset 0 0 0 2px map_get($pageColors, buttonDisabled);
    color: map_get($pageColors, buttonDisabled);
    pointer-events: none;
  }
}

.button-white {
  @include buttonWhite;
}

.buttons-full-row-mobile {
  display: flex;
  justify-content: space-between;
}

.button-flex {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.button-disabled {
  background-color: map_get($pageColors, buttonDisabled);
  border: 2px solid map_get($pageColors, buttonDisabled);
}

.button-details {
  @include buttonReset;
  @include setFontColor(greenFont);
  @include fontStyling($nunitoFont, 300);
  font-size: 1rem;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &_bold {
    @include fontStyling($nunitoFont, 600)
  }

  &_hidden {
    display: none !important;
  }
}

.link {
  @include linkReset;

  &_menu {
    @include menuLink;
    @include setThemeColor(color, themeColorBasicText);
    padding: 0.125rem 0;
  }

  &_success {
    @include setFontColor(greenFont)
  }

  &_black {
    text-decoration: underline;
    padding-top: 1rem;
    color: inherit;
  }

  &_block {
    display: block;
  }

  &_blue {
    color: #0069C5;
  }

  &_bold {
    font-weight: bolder;
  }
}

@include media-breakpoint-down(md) {
  .link {
    &_menu {
      padding: 0.5rem;
    }
  }
}