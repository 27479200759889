.switcher {
  &__container {
    @include setBackgroundColor(switcherGrey);
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    @include fontStyling($nunitoFont, 400);
    @include setBackgroundColor(switcherGrey);
    text-transform: uppercase;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;

    &_main {
      @include fontStyling($nunitoFont, 400);
      font-size: 1.25rem;
    }

    &_active {
      @include setFontColor(whiteFont);
      box-shadow: inset 0 0 0 2rem map_get($pageColors, success), 2px 2px 10px rgba(0, 0, 0, .2);
      z-index: 9;
    }

    &_single {
      box-shadow: none;
      background-color: transparent;
      padding: 1rem 0;
    }
  }

  &__select-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: .5rem 0;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: .5rem;
      width: .5rem;
      height: .5rem;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid map_get($pageColors, borderGrey);
    background-color: transparent;
    box-shadow: inset 0 0 4px map_get($pageColors, borderGrey);
    padding: .375rem 3rem .375rem .75rem;
    text-transform: uppercase;
    @include fontStyling($nunitoFont, 300);
    font-size: 1rem;
    cursor: pointer;

    &_menu {
      background-color: transparent;
      padding: .125rem 3rem .125rem .5rem;
      font-size: 1rem;
      //border: ;
    }
  }
}

@include media-breakpoint-down(md){
  .switcher__item {
    font-size: .6875rem;
  }
}