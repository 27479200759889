.special-offer-item {
  display: flex;
  margin-bottom: 2rem;
  justify-content: left;

  &__image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #F1F1F1 no-repeat center;
    background-size: cover;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__prices {
    margin-bottom: .5rem;
    font-size: 1.25rem;
  }

  &__price {
    font-weight: bolder;
    margin-right: 1rem;
  }

  &__old-price {
    color: #ACACAC;
  }

  &__type,
  &__name {
    font-size: .875rem;
  }

  &__type {
    font-weight: bolder;
  }

  &__name {
    margin: .5rem 0 1rem;
  }
}

.special-offer-item-add-to-cart {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 2rem;
}

@include media-breakpoint-down(xs) {
  .special-offer-item {
    width: 100%;
  }
}
