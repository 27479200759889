.header {
  position: relative;
  overflow: hidden;

  &__slider_el {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to right, rgba(243, 243, 243, 1) 0%,rgba(243, 243, 243, 1) 30%, rgba(243, 243, 243, 0) 100%);
      z-index: 1;
    }
  }

  &__slider-img {
    float: right;
    max-height: 30rem;
  }

  &__title-card {
    position: absolute;
    bottom: $insetSpacer;
    max-width: 60%;
  }

  &__sticker {
    position: absolute;
    top: $insetSpacer;
    left: 0;
  }

  &__badge {
    position: absolute;
    right: 0;
    bottom: $insetSpacer;
  }
}

@include media-breakpoint-down(sm) {
  .header {
    &__slider-img {
      object-position: center;
      max-height: unset;
      float: none;
      max-width: 100%;
    }

    & .slick-next {
      display: none !important;
    }

    &__slider_el {
      max-height:10rem;
      overflow: hidden;

      &:before {
        content: none;
      }
    }

    &__title-card {
      position: static;
      margin: 0;
      max-width: 100%;
    }

    &__sticker {
      top: 8rem;
      left: 0.5rem;
    }
  }
}
