.lecturers {
  &__card {
    margin-top: $spacer;
  }

  &__center-row {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
}

.lecturer-card {
  @include fontStyling($nunitoFont, 300);
  position: relative;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(100, 100, 100, .20);
  @include setThemeColor(color, themeColorDarkGreen);
  overflow: hidden;
  cursor: pointer;

  &__image,
  &__name,
  &__cta {
    position: absolute;
    width: 100%;
  }

  &__image {
    top: 0;
    object-fit: cover;
  }

  &__name {
    bottom: 0;
    display: flex;
    align-items: center;
    @include setThemeColor(background, themeColorLightGrey);
    padding: $insetSpacer $insetSpacer/2;
    height: 3.5rem;
  }

  &__cta {
    top:0;
    left:0;
    @include setThemeColor(background, themeColorTransparentGreen);
    padding: $insetSpacer/2;
    text-align: center;
    text-transform: uppercase;
    @include setFontColor(whiteFont);
    transition: .2s all;
    opacity: 0;

    &_show {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &:hover {
    .lecturer-card {
      &__name {
        @include setThemeColor(background, themeColorSoftGreen);
        @include setFontColor(whiteFont);
      }
    }
  }

  &__play-icon {
    position: absolute;
    top: $insetSpacer/2;
    right: $insetSpacer/2;
    fill: map_get($themeColors, themeColorDarkGreen);
    z-index: 1;
  }
}

.lecturers-button {
  @include setThemeColor(color, themeColorDarkGreen);
  @include fontStyling($nunitoFont, 600);
  font-size: 16px;
  text-transform: uppercase;

  &__arrow {
    border-right-color: map_get($pageColors, success);
    border-bottom-color: map_get($pageColors, success);
  }
}

@include media-breakpoint-down(sm){
  .lecturer-card {
    height: 220px;

    &__name {
      height: 4.5rem;
    }

    &__cta {
      opacity: 1;
      transform: translateY(-100%);
    }

    &:hover {
      .lecturer-card {
        &__name {
          @include setThemeColor(background, themeColorLightGrey);
          color: inherit;
        }
      }
    }
  }
}