.congress-program {
  box-shadow: 0 0 10px 0 rgba(49, 49, 49, 0.14);

  &__header,
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem 1rem;
  }

  &__header {
    @include setThemeColor(background, themeColorDarkGreen);
    border-radius: .25rem .25rem 0 0;
  }

  &__text {
    @include setFontColor(whiteFont);
    @include fontStyling($nunitoFont, 300);
    font-size: .875rem;
    text-transform: none;

    &_success {
      @include setFontColor(greenFont)
    }
  }

  &__content {
    @include setBackgroundColor(white);
  }

  &__item {
    width: 100%;
    padding: .5rem 1rem;
  }

  &__icon-box {
    display: flex;

    & > a {
      margin-right: 0.5rem;
    }
  }

  &__icon {
    fill: map_get($themeColors, themeColorDarkGreen);
  }

  &__icon-stroke {
    stroke: map_get($themeColors, themeColorDarkGreen);
  }

  &__arrow-success {
    border-bottom-color: map_get($pageColors, success);
    border-right-color: map_get($pageColors, success);
  }

  &__share-button {
    float: right;
  }

  &__align-right {
    display: flex;
    justify-content: flex-end;
    padding: $insetSpacer/2 $insetSpacer;
  }

  &__link-print {
    & svg {
      pointer-events: none;
    }
  }
}

.congress-program-footer {
  @include setThemeColor(background, themeColorLightGrey);
  border-radius: 0 0 .25rem .25rem;

  &__button {
    @include fontStyling($nunitoFont, 300);
    @include setThemeColor(color, themeColorDarkGreen);
    font-size: 14px;

    &_bold {
      font-size: 16px;
      @include fontStyling($nunitoFont, 600);
      text-transform: uppercase;
    }
  }

  &__icon {
    fill: map_get($themeColors, themeColorDarkGreen)
  }

  &__arrow {
    border-bottom-color: map_get($themeColors, themeColorDarkGreen);
    border-right-color: map_get($themeColors, themeColorDarkGreen);
  }
}

.congress-program-collapsed {
  width: 100%;

  &__title {
    @include fontStyling($nunitoFont, 300);
    @include setFontColor(darkGreyFont);
    padding: $insetSpacer;
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  &__list {
    border-bottom: 1px solid map_get($themeColors, themeColorDarkGreen);

    &:last-child {
      border-bottom: none;
    }
  }

  &__item {
    border-bottom: 1px solid map_get($pageColors, switcherGrey);
    padding: $insetSpacer;

    &_no-padding {
      padding: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__lecture-title-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__lecture-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include fontStyling($nunitoFont, 300);
    @include setFontColor(greyFont);
    font-size: 1.125rem;
    margin-bottom: .5rem;
  }

  &__room-badge {
    margin-bottom: .5rem;
  }

  &__lecture-lecturer {
    @include fontStyling($nunitoFont, 300);
    @include setFontColor(greenFont);
    padding: .25rem 0;
    font-size: 1rem;
  }
}

.congress-program-extended {
  width: 100%;
  &__title {
    @include fontStyling($nunitoFont, 300);
    @include setFontColor(darkGreyFont);
    padding: $insetSpacer 0;
    text-transform: uppercase;
    font-size: 1.25rem;

    &_inline {
      display: inline-block;
      line-height: 2rem;
    }
  }

  &__promo-info {
    @include fontStyling($nunitoFont, 600);
    @include setFontColor(greyFont);
    font-size: 1.125rem;
    display: inline-block;
    padding-left: $insetSpacer;
  }

  &__session {
    margin-bottom: $spacer;
  }

  &__room-badge {
    margin-bottom: 1rem;
  }
}

.congress-session {
  &__title-block {
    @include setFontColor(whiteFont);
    @include setThemeColor(background, themeColorDarkGreen);
    @include fontStyling($nunitoFont, 300);
    display: inline-block;
    text-transform: none;
    padding: $insetSpacer/2;
    font-size: 1.125rem;
  }

  &__title {
    margin: 0 .25rem .25rem 0;
  }
}

.congress-lecture {
  display: flex;
  @include fontStyling($nunitoFont, 300);
  @include setThemeColor(color, themeColorBasicText);
  padding: $insetSpacer 0;
  font-size: 18px;
  border-bottom: 1px solid map_get($pageColors, switcherGrey);

  &:last-child {
    border-bottom: none;
  }

  &__icon {
    margin-top: $spacer/4;
    fill: map_get($themeColors, themeColorDarkGreen)
  }

  &__title-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__title {
    padding: $insetSpacer/2 0;
  }

  &__sponsors-logo {
    margin-left: $spacer;
    max-height: 3rem;
  }

  &__lecturer {
    @include setFontColor(greenFont);
    padding-bottom: $insetSpacer/2;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    font-size: 1rem;
    font-weight: 600;
  }

  &__details {
    padding: $insetSpacer/2 0;
    font-size: 1rem;

    &_hide {
      display: none;
    }
  }

  &__price {
    padding: $insetSpacer/2 0;
    @include fontStyling($nunitoFont, 600);
  }
}

@include media-breakpoint-down(sm) {
  .congress-program {
    margin-right: -($spacer);
    margin-left: -($spacer);

    &__header,
    &__footer {
      border-radius: unset;
    }

    &__footer {
      justify-content: center;
    }

    &__icon-box {
      display: none;
    }

    &__button-mobile {
      display: inherit;
    }
  }

  .congress-program-extended {
    &__title {
      padding: $insetSpacer 0;
    }

    &__promo-info {
      padding-bottom: $insetSpacer;
    }
  }

  .congress-session {
    &__title-block {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    }
  }

  .congress-lecture {
    &__sponsors-logo {
      margin: 0 0 .5rem;
    }
  }
}