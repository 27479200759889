$displays: (block, inline-block, inline, flex, inline-flex, none, list-item, run-in, table-header-group, table-footer-group, table, inline-table, table-caption, table-cell, table-row, table-row-group, table-column, table-column-group);

@each $display in $displays {
  .d_#{$display} {
    @include media-breakpoint-up(xs) {
      display: $display !important;
    }
  }
}


@each $breakpoint, $width in $grid-breakpoints {
  @each $display in $displays {
    .d_#{$breakpoint}_#{$display} {
      @include media-breakpoint-up($breakpoint) {
        display: $display !important;
      }
    }
  }
}