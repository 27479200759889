.footer-magwet {
  background-color: map_get($magwetColors, footerBackground);
  color: map_get($magwetColors, footerGrey);
  @include fontStyling($openFont, 400);
  padding: 1rem 0;
  position: relative;
  z-index: 20;

  &__link, &__text {
    color: map_get($magwetColors, footerGrey);
    text-decoration: none;
    display: block;
    padding: .25rem 0;
    &_white {
      color: map_get($magwetColors, footerWhite);
    }
  }

  &__link {
    &:hover, &:focus, &:active {
      color: map_get($magwetColors, footerGrey);
      text-decoration: underline;
    }
  }

  &__section {
    padding: 1rem 0;
  }

  &__title {
    color: map_get($magwetColors, footerWhite);
    @include fontStyling($robotoCondensedFont, 600);
    text-transform: uppercase;
    margin-bottom: .5rem;
    display: block;
  }

  &__contacts {
    margin-top: 3rem;
  }
}

@include media-breakpoint-down(sm) {
  .footer-magwet {
    position: static;

    &__title-row {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__items {
      max-height: 0;
      opacity: 0;
      visibility: hidden;

      & .footer-podyplomie__link, .footer-podyplomie__text {
        max-height: 0;
      }

      &_show {
        max-height: 1000px;
        opacity: 1;
        visibility: visible;
        & .footer-podyplomie__link, .footer-podyplomie__text {
          max-height: 100px;
        }

      }
    }
  }
}