.ticket-offer {
  &__text {
    font-size: 1.125rem;
    @include fontStyling($nunitoFont, 300);
    line-height: 1.2;

    &_sm {
      @include fontStyling($nunitoFont, 300);
      line-height: 1;
      font-size: .875rem;
    }

    &_title {
      text-align: center;
      margin-bottom: 2rem;
      font-weight: bolder;
    }
  }

  &__info-button {
    padding: .25rem;
    font-size: .875rem;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 1rem;
  }

  &__show-tooltip-container {
    position: relative;
    display: inline-block;
  }

  // content loaded as html object with no class names
  &__content {
    margin: 1rem 0;

    &, & p {
      font-size: .875rem;
      @include fontStyling($nunitoFont, 300);
      line-height: 1.2;
    }

    & ul {
      margin: .5rem 0;
      list-style: none;
      @include fontStyling($nunitoFont, 300);
      font-size: .875rem;
      line-height: 1.2;


      & li:before {
        content: '✓';
        margin: 0 .5rem;
      }
    }

    & a {
      color: white;
    }
  }
}