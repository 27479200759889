.menu-link {
  display: flex;
  align-items: center;
  padding: .875rem .5rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  margin: 0 -.25rem;
  cursor: pointer;
  font-size: .875rem;

  &__login {
    line-height: 20px;

    &_green {
      background-color: map_get($magwetColors, success);
    }
  }

  &_society {
    text-transform: none;
    color: map_get($magwetColors, darkBlueText);
    font-size: .875rem;

    &:hover {
      color: white;
    }
  }

  &__badge {
    position: absolute;
    text-align: center;
    top: 0;
    left: 20px;
    border-radius: 50%;
    background-color: map_get($magwetColors, success);
    width: 1rem;
    height: 1rem;
    font-size: .75rem;
    line-height:1rem;
  }

  &__dropdown {
    &_open {
      background-color: white;
      color: map_get($magwetColors, main);

      &.mobile-menu__link:after{
        content: none;
      }
    }
  }

  &_logo {
    max-width: 180px;
  }

  &_highlighted {
    color: map_get($magwetColors, enhance)
  }
}

@include media-breakpoint-down(md) {
  .menu-link {
    padding: .5rem .25rem;
    font-size: .875rem;
    text-transform: none;

    &_society {
      color: white;
    }

    &_logo {
      max-width: 120px;
    }

    &_mobile {
      width: 100%;
      margin-right: 0;
      justify-content: center;
    }

    &__dropdown {
      width: 100%;

      &_open {
        @include setThemeColor(background, themeColorDarkGreen);
        color: white;

      }
    }
  }
}