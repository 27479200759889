.edu-points-badge {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: .25rem;
  border-radius: 2rem;
  margin-right: 1rem;

  &__number {
    @include fontStyling($openFont, 700);
    @include setFontColor(whiteFont);
    display: inline-block;
    box-shadow:0 0 0 10rem rgba(255, 255, 255, 0.8);
    padding: .5rem;
    border-radius: 50%;
    font-size: 1.375rem;
    line-height: 1.375rem;
    text-align: center;
    width: 2.75rem;
    height: 2.75rem;
    background-color: rgba(40, 53, 131, 0.25);
  }

  &__text {
    @include fontStyling($openFont, 400);
    @include setThemeColor(color, themeColorDarkGreen);
    text-transform: uppercase;
    font-size: 14px;
  }

  &__element {
    margin-right: $insetSpacer/2;
  }
}

@include media-breakpoint-down(md) {
  .edu-points-badge {
    display: none;
  }
}