.title-card {
  display: flex;
  padding: $insetSpacer;
  flex-direction: column;
  @include setBackgroundColor(whiteOnOpacity);
  text-transform: uppercase;

  &__element {
    margin: .25rem 0;
  }

  &__place-n-date {
    @include setThemeColor(color, themeColorDarkGreen);
    @include fontStyling($openFont, 400);
    font-size: 1.25rem;
  }

  &__congress-number {
    @include setThemeColor(background, themeColorLightBlue);
    @include setFontColor(whiteFont);
    @include fontStyling($openFont, 600);
    font-size: 1.25rem;
    display: inline-block;
    padding: .25rem;
  }

  &__specialization {
    @include setThemeColor(color, themeColorDarkGreen);
    @include fontStyling($bebasFont, 400);
    font-size: 6.25rem;
    line-height: 1.1;
  }

  &__claim {
    @include setThemeColor(color, themeColorDarkGreen);
    @include fontStyling($bebasFont, 400);
    font-size: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  .title-card {
    max-width: unset;

    &__specialization {
      font-size: 70px;
      padding-top: 0;
    }

    &__claim {
      margin-top: -.5rem;
    }
  }
}