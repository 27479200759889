.subscribe-link {
  text-align: left;
  text-transform: none;
}

@include media-breakpoint-down(lg) {
  .subscribe-link {
    border: 2px solid white;
    border-radius: 3px;
    padding: .5rem;
  }
}

@include media-breakpoint-down(md) {
  .subscribe-link {
    border: none;
  }
}