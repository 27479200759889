$border: 2px;

.loader-round {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  right: -1rem;
  background-color: transparent;
  animation: rotator .75s infinite linear;
  width: 16px;
  height: 16px;
  border: $border solid;
  border-color: #fff #fff #fff transparent;
  border-radius: 50%;


  &_hide {
    display: none;
  }
}

@keyframes rotator {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


