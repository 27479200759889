.marketing-alert {
  text-align: left;
  display: inline-block;
  @include fontStyling($nunitoFont, 300);
  color: white;
  background-color: map_get($themeColors, themeColorLightGreen);
  padding: .5rem;
  font-size: .875rem;
  border-radius: 3px;


  &__button {
    background-color: white;
    @include fontStyling($nunitoFont, 700);
    text-transform: uppercase;
    color: map_get($themeColors, themeColorLightGreen);
    border: none;
    border-radius: 1px;
    padding: 0;
    line-height: .75;
  }

  &__link {
    @include fontStyling($nunitoFont, 600);
    @include setFontColor(whiteFont);
    @include setBackgroundColor(transparent);
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;

    &_lg {
      margin-top: .25rem;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
}

.marketing-alert-ticket {
  display: inline-flex;
  align-items: center;
}

.marketing-alert-menu {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;

  & > p {
    text-align: right;
    margin-bottom: .25rem;
    line-height: 1.2;
  }
}

.marketing-alert-header {
  font-size: 1rem;
}

@include media-breakpoint-down(md) {
  .marketing-alert-menu {
    display: none;
  }

  .marketing-alert-header {
    top: 7.5rem;
  }
}