.input {
  &_red {
    border: 1px solid red !important;
  }

  &[type='checkbox'] {
    margin-right: .5rem;
  }
}

.textarea, .input[type='text'], .input[type='email'], .input[type='tel'] {
  box-shadow: inset 0 0 6px rgba(8, 113, 75, .2);
  @include setBackgroundColor(simpleWhite);
  padding: 1rem;
  border: none;
}

input[type='number'] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}