@import '../../commons/grid';
@import 'hero';
@import 'header';
@import 'title_card';
@import 'promo_sticker';
@import 'educational_points_badge';
@import 'switcher';
@import 'menu_fixed';
@import 'details_for_city';
@import 'invitation';
@import 'congress_program';
@import 'basic_info';
@import 'lecturers';
@import 'popup';
@import 'tickets';
@import 'single_ticket';
@import 'gallery_attached';
@import 'congress_sponsors';
@import 'congress_exhibitors';
@import 'congress_promoter';
@import 'congress_practical_info';
@import 'accordion';
@import 'dropdown';
@import 'contact';
@import 'dropdown';
@import 'bouncing_loader';
@import 'ticket_purchase/purchase_summary';
@import 'ticket_purchase/ticket_purchase_amount';
@import 'ticket_purchase/special_offer';
@import 'ticket_purchase/special_offer_item';
@import 'ticket_purchase/special_offer';
@import 'ticket_purchase/main_summary';
@import 'ticket_purchase/workshops_entertainment';
@import 'ticket_purchase/add_to_cart_form';
@import 'marketing_alerts/all';
@import 'patronage';
@import 'video_invitation';

html, body {
  scroll-behavior: smooth;
}

@include media-breakpoint-down(md) {
  body {
    margin-bottom:1rem;
  }
}
