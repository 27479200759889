@mixin linkReset {
  text-decoration: none;
  display: inline-block;
}

@mixin buttonReset {
  background-color: transparent;
  border: none;
}

@mixin basicButton {
  @include linkReset;
  @include buttonReset;
  font-size: 18px;
  border-radius: 2px;
  padding: .25rem 1rem;
  line-height: 1;

  @include media-breakpoint-down(sm){
    padding:0.5rem 0.25rem;
    margin: 0.25rem 0.5rem;
    font-weight: 600;
    font-size: 16px;
  }
}

@mixin buttonSuccess {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(success);
  @include setFontColor(whiteFont);
  border: 2px solid  map_get($pageColors, success);
  text-align: center;

}

@mixin buttonInactive {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(buttonDisabled);
  @include setFontColor(whiteFont);
}

@mixin buttonSuccessReverted {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(transparent);
  @include setFontColor(greenFont);
  box-shadow: inset 0 0 0 2px map_get($pageColors, success);
}

@mixin buttonWhite {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(white);
  @include setFontColor(greenFont);
  margin: 0 .5rem;
}

@mixin menuLink {
  @include linkReset;
  @include fontStyling($nunitoFont, 600);
  text-transform: uppercase;
}