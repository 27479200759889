.header-magwet {
  background-color: map_get($magwetColors, main);
  @include fontStyling($openFont, 400);
  z-index: 21;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__item {
    display: flex;
    margin: 0 .25rem;
    height: 100%;
    align-self: center;

    &_relative {
      position: relative;
    }

    &_society {
      padding: 0 .5rem;
      background-color: map_get($magwetColors, success);
    }
  }

  &__logo {
    width: 100%;
    min-width: 130px;
  }

  &__wpd-link {
    text-decoration: none;
    color: map_get($magwetColors, success);

    &:hover {
      .header-magwet__wpd-link_white {
        color: map_get($magwetColors, success);
      }
    }

    &_white {
      color: white;
    }
  }

  &__form {
    display: flex;
    flex-grow: 1;
    padding: 0 1.5rem;
  }

  &__search-input {
    flex-grow: 1;
    border: none;
    background: white;
    height: 2rem;
    line-height: 2em;
    border-radius: 2px;
    padding-left: .5rem;
    font-size: 1rem;
  }

  &__separator {
    width: 100%;
    margin-top: .5rem;

    &_vertical {
      flex: 1;
    }
  }
}

.menu-link {
  display: flex;
  align-items: center;
  padding: .375rem .25rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  @include fontStyling($nunitoFont, 400);
  border: none;

  &__login {
    line-height: 20px;

    &_green {
      background-color: map_get($magwetColors, success);
    }
  }

  &_special {
    color: map_get($magwetColors, enhance);

    &:hover, &:active, &:focus {
      color: map_get($magwetColors, enhance);
    }
  }

  &_promo {
    font-weight: 900;
    font-style: italic;
    position: relative;
  }

  &__promo-badge {
    position: absolute;
    top: -60%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__badge {
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: map_get($magwetColors, success);
    width: 1rem;
    height: 1rem;
    font-size: .75rem;
    line-height:1rem;
  }

  &_dropdown {
    &:after {
      content: '';
      position: absolute;
      right: -5px;
      top: 50%;
      width: .375rem;
      height: .375rem;
      transform: translateY(-50%);
      background-color: white;
      clip-path: polygon(50% 70%, 0 0, 100% 0);
    }
  }

  &_logo {
    max-width: 180px;
  }

  &_subscribe {
    padding: .5rem;
    background-color: map_get($magwetColors, subscribe);
    display: inline-flex;
    text-align: left;
    text-transform: none;
  }

  &_highlighted {
    color: map_get($magwetColors, enhance)
  }
}

.mobile-menu {
  display: flex;
  align-items: center;
}

@include media-breakpoint-up(lg) {
  .header-magwet {
    &__item {
      &_grow {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .menu-link {
    font-size: .8125rem;
  }

  .header-magwet {
    &__item {
      &_society {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header-magwet {
    &__list {
      align-items: flex-start;
    }

    &__container {
      max-height: 49px;
    }

    &__item {
      margin: 0;
      padding: .5rem;
      align-self: unset;
      height: unset;

      &_society {
        background-color: unset;
      }
    }

    &__form {
      padding: 0;
    }
  }

  .menu-link {
    padding: .5rem .25rem;

    &_logo {
      max-width: 120px;
    }

    &_mobile {
      width: 100%;
      margin-right: 0;
      justify-content: center;
    }

    &_dropdown {
      width: 100%;

      &:after {
        background-color: black;

      }
    }

    &__promo-badge {
      top: 50%;
      left: unset;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }

  .mobile-menu {
    background-color: white;
    position: absolute;
    flex-direction: column;
    z-index: 5;
    left: 0;
    top: 43px;
    width: 80%;
    max-width: 20rem;
    padding: 1rem 2rem;
    height: calc(100% - 120px);
    transition: .3s transform;
    transform: translateX(-100%);

    &_show {
      transform: translateX(0%);
    }

    &__list {
      flex-direction: column;
      width: 100%;
      max-height: 60%;
      overflow-y: scroll;
    }

    &__item {
      padding: 0 1rem;
      border-bottom: 1px solid black;
      width: 100%;
      flex-direction: column;

      &_mt {
        border-bottom: none;
        margin-top: .5rem;
      }
    }

    &__link {
      color: black;
      padding: 1rem 0;
    }
  }
}
