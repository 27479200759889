.room-badge {
  @include setBackgroundColor(success);
  @include fontStyling($nunitoFont, 600);
  display: inline-block;
  color: white;
  padding: .5rem;
  font-size: 1.2rem;
  border-radius: 1px;

  &_small {
    border-radius: 3px;
    font-size: .875rem;
    line-height: 1;
    padding: .375rem;
  }
}

@include media-breakpoint-down(sm) {
  .room-badge {
    display: inline;
    font-size: 1rem;
    padding: .125rem .5rem;
    border-radius: 2px;
  }
}