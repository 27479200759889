.icon {
  margin-left: $insetSpacer;

  &_margined {
    margin: 0 .375rem;
  }

  &_white {
    fill: white;
  }

  &_theme-color {
    fill: map_get($themeColors, themeColorDarkGreen);
  }

  &_grey {
    fill:#6b6b6b
  }

  &_absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_pdf-color {
    & .a{fill:#e2e5e7;}
    & .b{fill:#b0b7bd;}
    & .c{fill:#cad1d8;}
    & .d{fill:#f15642;}
    & .e{fill:#fff;}
  }

  &_arrow {
    fill: none;
    stroke: white;
    stroke-width: 1px;
    fill-rule: evenodd;
    transform: translateY(-5%);

    &_white {
      stroke: white;
    }

    &_green {
      stroke: map_get($themeColors, themeColorDarkGreen);
    }

    &_down {
      transform: rotate(180deg) translateY(-10%);
    }

    &_disabled {
      stroke: rgba(233, 233, 233, .3)
    }
  }
}